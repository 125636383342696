html {
  font-size: 62.5%;
}

body {
  font-family: Tahoma, Verdana, Segoe, sans-serif;
  text-align: center;
  background: black;
  color: white;
}

.large {
  font-size: 2.5rem;
}

.medium {
  font-size: 2rem;
}

.text-primary {
  font-size: 1.5rem;
}

.small {
  font-size: 1rem;
}

a {
  color: pink;
  text-decoration: none;
}

.inactive-link {
  color: darkgray;
  text-decoration: line-through;
}

.banner {
  width: 100%;
}

img {
  width: 50%;
}

.logo {
  width: 20%;
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.navbar ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

.navbar ul li {
  text-transform: uppercase;
  list-style: none;
}

.item {
  padding: 10px;
}

.main {
  margin-top: 1%;
  margin-bottom: 1%;
  color: white;
}

.footer {
  margin-top: 5%;
  margin-bottom: 50px;
}
